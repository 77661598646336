import React, { useState, useEffect, useRef } from "react";
import { Drawer } from "antd";
import ImageCarousel1 from "../asset/HERO-BANNER/1.jpg";
import ImageCarousel2 from "../asset/HERO-BANNER/2.jpg";
import ImageCarousel3 from "../asset/HERO-BANNER/3.jpg";
import ImageCarousel4 from "../asset/HERO-BANNER/4.jpg";
import ImageCarousel5 from "../asset/HERO-BANNER/5.jpg";
import ImageCarousel6 from "../asset/HERO-BANNER/6.jpg";
import ImageCarousel7 from "../asset/HERO-BANNER/7.jpg";
import ImageCarousel8 from "../asset/HERO-BANNER/8.jpg";
import ImageCarousel9 from "../asset/HERO-BANNER/9.jpg";
import ImageCarousel10 from "../asset/HERO-BANNER/10.jpg";
import LogoThiskyhall from "../asset/Thiskyhall-Logo.png";
import IconArrown from "../asset/icon-arrown.svg";
import Carousel from "react-bootstrap/Carousel";
import IconMenu from "../asset/icon-menu.svg";

function Header(props) {
  const carouselRef = useRef(null);

  const handleWheel = (e) => {
    const carousel = carouselRef.current;
    if (e.deltaY > 0) {
      carousel.next(); // Cuộn xuống, chuyển đến slide tiếp theo
    } else {
      carousel.prev(); // Cuộn lên, chuyển đến slide trước đó
    }
  };
  const {
    ConstantLanguage,
    handleScrollToNowHappening,
    handleScrollToExplore,
    handleScrollToSlider,
    handleScrollToAboutus,
    handleScrollToConsult,
    handleScrollToFooter,
    setIsChangeLanguage,
    isChangeLanguage,
    windowSize,
  } = props;
  const [isChangeBackground, setIsChangeBackground] = useState(true);
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  });
  const listenScrollEvent = (event) => {
    if (window.scrollY > (windowSize.innerWidth > 813 ? 200 : 50)) {
      return setIsChangeBackground(true);
    } else {
      return setIsChangeBackground(true);
    }
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="header">
      {windowSize.innerWidth > 815 ? (
        <div
          className={
            isChangeBackground === false
              ? "header-navbar"
              : "header-navbar header-navbar--background"
          }
        >
          <div className="header-navbar__left">
            <span onClick={() => handleScrollToAboutus()}>
              {ConstantLanguage.header__about}
            </span>
            <span onClick={() => handleScrollToExplore()}>
              {ConstantLanguage.header__explore}
            </span>
            <span onClick={() => handleScrollToSlider()}>
              {ConstantLanguage.header__event}
            </span>
            
            {/* <span onClick={() => handleScrollToAboutus()}>
              {ConstantLanguage.header__booking}
            </span> */}
            <span
              onClick={() => {
                handleScrollToConsult();
              }}
            >
              {ConstantLanguage.header__contact}
            </span>
          </div>
          <div className="header-navbar__mid">
            <div
              className="header-navbar__mid--image"
              onClick={() => {
                window.scrollTo(0, 0, "smooth");
              }}
            >
              <img src={LogoThiskyhall} alt="" />
            </div>
          </div>
          <div className="header-navbar__right">
            <div
              className="header-navbar__right--button"
              onClick={() => {
                handleScrollToConsult();
              }}
            >
              {ConstantLanguage.booking}
            </div>

            <div className="header-navbar__right--language">
              <span
                className={
                  isChangeLanguage == "vi"
                    ? "header-navbar__right--language--active"
                    : ""
                }
                onClick={() => {
                  setIsChangeLanguage("vi");
                }}
              >
                VI
              </span>
              <span> | </span>
              <span
                onClick={() => {
                  setIsChangeLanguage("en");
                }}
                className={
                  isChangeLanguage == "en"
                    ? "header-navbar__right--language--active"
                    : ""
                }
              >
                EN
              </span>
            </div>
          </div>
        </div>
      ) : (
        // mobile devices
        <div
          className={
            isChangeBackground === false
              ? "header-navbar"
              : "header-navbar header-navbar--background"
          }
        >
          <div className="header-navbar-mobile--block1"></div>
          <div className="header-navbar-mobile--block2">
            <div
              className="header-navbar__mid--image"
              onClick={() => {
                window.scrollTo(0, 0, "smooth");
              }}
            >
              <img src={LogoThiskyhall} alt="" />
            </div>
          </div>
          <div className="header-navbar-mobile--block3">
            <div
              className="header-navbar-mobile--block3--button"
              onClick={showDrawer}
            >
              <img src={IconMenu} alt="" />
            </div>
            <Drawer title="" placement="right" onClose={onClose} open={open}>
              <div className="header-navbar-mobile__drawer">
                <span
                  onClick={() => {
                    handleScrollToAboutus();
                    setOpen(false);
                  }}
                >
                  {ConstantLanguage.header__about}
                </span>
                
                {/* <span
                  onClick={() => {
                    handleScrollToAboutus();
                    setOpen(false);
                  }}
                >
                  {ConstantLanguage.header__booking}
                </span> */}
                <span
                  onClick={() => {
                    handleScrollToExplore();
                    setOpen(false);
                  }}
                >
                  {ConstantLanguage.header__explore}
                </span>
                <span
                  onClick={() => {
                    handleScrollToSlider();
                    setOpen(false);
                  }}
                >
                  {ConstantLanguage.header__event}
                </span>
                
                <span
                  onClick={() => {
                    handleScrollToFooter();
                    setOpen(false);
                  }}
                >
                  {ConstantLanguage.header__contact}
                </span>
                <div
                  className="header-navbar__right--button1"
                  onClick={() => {
                    handleScrollToConsult();
                    setOpen(false);
                  }}
                >
                  {ConstantLanguage.booking}
                </div>
                <div className="header-navbar__right--language">
                  <span
                    className={
                      isChangeLanguage == "vi"
                        ? "header-navbar__right--language--active"
                        : ""
                    }
                    onClick={() => {
                      setIsChangeLanguage("vi");
                      setOpen(false);
                    }}
                  >
                    VI
                  </span>
                  <span> | </span>
                  <span
                    onClick={() => {
                      setIsChangeLanguage("en");
                      setOpen(false);
                    }}
                    className={
                      isChangeLanguage == "en"
                        ? "header-navbar__right--language--active"
                        : ""
                    }
                  >
                    EN
                  </span>
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      )}
      <div
        className="header-slider"
        //  onWheel={handleWheel}
      >
        <Carousel
          ref={carouselRef}
          interval={8000}
          controls={true}
          touch="true"
          pause="false"
        >
          <Carousel.Item>
            <div className="header_wrapper_image">
              <img src={ImageCarousel1} alt="" />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="header_wrapper_image">
              <img src={ImageCarousel2} alt="" />
            </div>
            <Carousel.Caption>
              <div className="header-slider--content1">
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="header_wrapper_image">
              <img src={ImageCarousel3} alt="" />
            </div>
          </Carousel.Item>
          <Carousel.Item>
          <div className="header_wrapper_image">
              <img src={ImageCarousel4} alt="" />
            </div>
          </Carousel.Item>
          <Carousel.Item>
          <div className="header_wrapper_image">
              <img src={ImageCarousel5} alt="" />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="header_wrapper_image">
              <img src={ImageCarousel6} alt="" />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="header_wrapper_image">
              <img src={ImageCarousel7} alt="" />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="header_wrapper_image">
              <img src={ImageCarousel8} alt="" />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="header_wrapper_image">
              <img src={ImageCarousel9} alt="" />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="header_wrapper_image">
              <img src={ImageCarousel10} alt="" />
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

export default Header;

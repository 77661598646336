import React, { useEffect, useState } from "react";
import BGConsult from "../asset/BG_Footer_1920x800_4.jpg";
import IconArrown from "../asset/icon-arrown.svg";
import { Input, DatePicker, message, Select, notification } from "antd";
import dayjs from "dayjs";

import locale from "antd/es/date-picker/locale/vi_VN";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import NotifySucess from "./notifySucess";

const { TextArea } = Input;

function Consult(props) {
	const { ConstantLanguage, isChangeLanguage } = props;
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [career, setCareer] = useState("");
	const [guestTotal, setGuestTotal] = useState("");
	const [date, setDate] = useState("");
	const [content, setContent] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [selectedValue, setSelectedValue] = useState(
		ConstantLanguage.consult__typeOfEvent,
	);
	const [isModalOpen, setIsModalOpen] = useState(false);

	// const config ={
	// 	Username: 'thiskyhall-info@thiso.vn',
	// 	Password: "067D846594D085F4E5F2DE246862D69B4F8D",
	// 	Host: "smtp.elasticemail.com",
	// 	Port: 2525,
	// 	To: "southern.tran21@gmail.com",
	// 	From: "tranphuongnam21.06.99@gmail.com",
	// 	Subject: "This is a Subject",
	// 	Body: "And this is the body"
	// }
	useEffect(() => {
		setSelectedValue(null);
		if (isChangeLanguage == "vi") {
			// Chọn phần tử bạn muốn theo dõi

			let datePickerClick = document.querySelector(".ant-picker");

			datePickerClick.addEventListener("click", (e) => {
				const targetElement = document.querySelector(".ant-picker-month-btn");
				const targetElementYear = document.querySelector(
					".ant-picker-year-btn",
				);
				const button2 = document.querySelector(".ant-picker-header-next-btn");
				const button3 = document.querySelector(".ant-picker-header-prev-btn");

				if (targetElement || targetElementYear) {
					targetElement.disabled = true;
					targetElementYear.disabled = true;
					switch (targetElement.innerHTML) {
						case "Jan":
							targetElement.innerHTML = "Tháng 1";
							break;
						case "Feb":
							targetElement.innerHTML = "Tháng 2";
							break;
						case "Mar":
							targetElement.innerHTML = "Tháng 3";
							break;
						case "Apr":
							targetElement.innerHTML = "Tháng 4";
							break;
						case "May":
							targetElement.innerHTML = "Tháng 5";
							break;
						case "Jun":
							targetElement.innerHTML = "Tháng 6";
							break;
						case "Jul":
							targetElement.innerHTML = "Tháng 7";
							break;
						case "Aug":
							targetElement.innerHTML = "Tháng 8";
							break;
						case "Sep":
							targetElement.innerHTML = "Tháng 9";
							break;
						case "Oct":
							targetElement.innerHTML = "Tháng 10";
							break;
						case "Nov":
							targetElement.innerHTML = "Tháng 11";
							break;
						case "Dec":
							targetElement.innerHTML = "Tháng 12";
							break;
						default:
							break;
					}
				}

				button2.addEventListener("click", () => {
					setTimeout(() => {
						switch (targetElement.innerHTML) {
							case "Jan":
								targetElement.innerHTML = "Tháng 1";
								break;
							case "Feb":
								targetElement.innerHTML = "Tháng 2";
								break;
							case "Mar":
								targetElement.innerHTML = "Tháng 3";
								break;
							case "Apr":
								targetElement.innerHTML = "Tháng 4";
								break;
							case "May":
								targetElement.innerHTML = "Tháng 5";
								break;
							case "Jun":
								targetElement.innerHTML = "Tháng 6";
								break;
							case "Jul":
								targetElement.innerHTML = "Tháng 7";
								break;
							case "Aug":
								targetElement.innerHTML = "Tháng 8";
								break;
							case "Sep":
								targetElement.innerHTML = "Tháng 9";
								break;
							case "Oct":
								targetElement.innerHTML = "Tháng 10";
								break;
							case "Nov":
								targetElement.innerHTML = "Tháng 11";
								break;
							case "Dec":
								targetElement.innerHTML = "Tháng 12";
								break;
							default:
								break;
						}
					}, 1);
				});
				button3.addEventListener("click", () => {
					setTimeout(() => {
						switch (targetElement.innerHTML) {
							case "Jan":
								targetElement.innerHTML = "Tháng 1";
								break;
							case "Feb":
								targetElement.innerHTML = "Tháng 2";
								break;
							case "Mar":
								targetElement.innerHTML = "Tháng 3";
								break;
							case "Apr":
								targetElement.innerHTML = "Tháng 4";
								break;
							case "May":
								targetElement.innerHTML = "Tháng 5";
								break;
							case "Jun":
								targetElement.innerHTML = "Tháng 6";
								break;
							case "Jul":
								targetElement.innerHTML = "Tháng 7";
								break;
							case "Aug":
								targetElement.innerHTML = "Tháng 8";
								break;
							case "Sep":
								targetElement.innerHTML = "Tháng 9";
								break;
							case "Oct":
								targetElement.innerHTML = "Tháng 10";
								break;
							case "Nov":
								targetElement.innerHTML = "Tháng 11";
								break;
							case "Dec":
								targetElement.innerHTML = "Tháng 12";
								break;
							default:
								break;
						}
					}, 1);
				});

				let days = document.querySelectorAll(".ant-picker-content tr th");
				let daysOfWeekInVietnamese = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];

				days.forEach((th, index) => {
					th.innerHTML = daysOfWeekInVietnamese[index];
				});
			});
		}

		if (isChangeLanguage == "en") {
			let datePickerClick = document.querySelector(".ant-picker");
			let daysOfWeekInVietnamese = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

			datePickerClick.addEventListener("click", (e) => {
				const targetElement = document.querySelector(".ant-picker-month-btn");
				const targetElementYear = document.querySelector(
					".ant-picker-year-btn",
				);
				const button2 = document.querySelector(".ant-picker-header-next-btn");
				const button3 = document.querySelector(".ant-picker-header-prev-btn");
				if (targetElement || targetElementYear) {
					targetElement.disabled = true;
					targetElementYear.disabled = true;
					switch (targetElement.textContent) {
						case "Tháng 1":
							targetElement.textContent = "Jan";
							break;
						case "Tháng 2":
							targetElement.textContent = "Feb";
							break;
						case "Tháng 3":
							targetElement.textContent = "Mar";
							break;
						case "Tháng 4":
							targetElement.textContent = "Apr";
							break;
						case "Tháng 5":
							targetElement.textContent = "May";
							break;
						case "Tháng 6":
							targetElement.textContent = "Jun";
							break;
						case "Tháng 7":
							targetElement.textContent = "Jul";
							break;
						case "Tháng 8":
							targetElement.textContent = "Aug";
							break;
						case "Tháng 9":
							targetElement.textContent = "Sep";
							break;
						case "Tháng 10":
							targetElement.textContent = "Oct";
							break;
						case "Tháng 11":
							targetElement.textContent = "Nov";
							break;
						case "Tháng 12":
							targetElement.textContent = "Dec";
							break;
						default:
							break;
					}
				}

				button2.addEventListener("click", () => {
					setTimeout(() => {
						switch (targetElement.textContent) {
							case "Tháng 1":
								targetElement.textContent = "Jan";
								break;
							case "Tháng 2":
								targetElement.textContent = "Feb";
								break;
							case "Tháng 3":
								targetElement.textContent = "Mar";
								break;
							case "Tháng 4":
								targetElement.textContent = "Apr";
								break;
							case "Tháng 5":
								targetElement.textContent = "May";
								break;
							case "Tháng 6":
								targetElement.textContent = "Jun";
								break;
							case "Tháng 7":
								targetElement.textContent = "Jul";
								break;
							case "Tháng 8":
								targetElement.textContent = "Aug";
								break;
							case "Tháng 9":
								targetElement.textContent = "Sep";
								break;
							case "Tháng 10":
								targetElement.textContent = "Oct";
								break;
							case "Tháng 11":
								targetElement.textContent = "Nov";
								break;
							case "Tháng 12":
								targetElement.textContent = "Dec";
								break;
							default:
								break;
						}
					}, 1);
				});
				button3.addEventListener("click", () => {
					setTimeout(() => {
						switch (targetElement.textContent) {
							case "Tháng 1":
								targetElement.textContent = "Jan";
								break;
							case "Tháng 2":
								targetElement.textContent = "Feb";
								break;
							case "Tháng 3":
								targetElement.textContent = "Mar";
								break;
							case "Tháng 4":
								targetElement.textContent = "Apr";
								break;
							case "Tháng 5":
								targetElement.textContent = "May";
								break;
							case "Tháng 6":
								targetElement.textContent = "Jun";
								break;
							case "Tháng 7":
								targetElement.textContent = "Jul";
								break;
							case "Tháng 8":
								targetElement.textContent = "Aug";
								break;
							case "Tháng 9":
								targetElement.textContent = "Sep";
								break;
							case "Tháng 10":
								targetElement.textContent = "Oct";
								break;
							case "Tháng 11":
								targetElement.textContent = "Nov";
								break;
							case "Tháng 12":
								targetElement.textContent = "Dec";
								break;
							default:
								break;
						}
					}, 1);
				});

				let days = document.querySelectorAll(".ant-picker-content tr th");
				days.forEach((th, index) => {
					th.innerHTML = daysOfWeekInVietnamese[index];
				});
			});
		}
	}, [isChangeLanguage]);

	useEffect(() => {
		const inputElement = document.getElementById("positiveNumberInput");

		inputElement.addEventListener("input", function () {
			let inputValue = inputElement.value;

			// Loại bỏ các số 0 đằng trước
			inputValue = inputValue.replace(/^0+/, "");

			// Đặt giá trị của trường input thành giá trị đã loại bỏ số 0 đằng trước
			setGuestTotal(inputValue);

			// Kiểm tra nếu giá trị là số âm, thiết lập giá trị của trường input thành 0
			const numericValue = parseFloat(inputValue);
			if (numericValue < 0) {
				setGuestTotal(0);
			}
		});
	}, []);

	const handleSubmit = () => {
		if (
			name != "" &&
			email != "" &&
			phone != "" &&
			date != "" &&
			selectedValue != null &&
			guestTotal != ""
		) {
			setIsLoading(true);
			axios
				.post("https://sheetdb.io/api/v1/g7kbooc1cw5n2", {
					JSON: {
						No: 1,
						"Họ và tên": name,
						Email: email,
						"Số ĐT": phone,
						"Ngày đặt tiệc": date,
						"Nội dung": content,
						"Ngày nhập": new Date().toLocaleString(),
						"Loại hình sự kiện": selectedValue,
						"Số lượng khách": guestTotal,
						"Tên công ty": companyName,
						"Ngành nghề": career,
					},
				})
				.then((response) => {
					console.log(response.status);
					if (response.status === 201) {
						setContent("");
						setDate("");
						setEmail("");
						setName("");
						setPhone("");
						setCompanyName("");
						setGuestTotal("");
						setCareer("");
						setSelectedValue(null);
						setIsModalOpen(true);
						setIsLoading(false);
					} else {
						axios({
							method: "post",
							url: "https://v1.nocodeapi.com/southerntran/google_sheets/yAzDUDfrlLtAYXMm",
							params: { tabId: "Information" },
							data: [
								[
									"",
									name,
									email,
									phone,
									companyName,
									career,
									selectedValue,
									guestTotal,
									date,
									content,

									new Date().toLocaleString(),
								],
							],
						}).then((response) => {
							if (response.status === 201) {
								setContent("");
								setDate("");
								setEmail("");
								setName("");
								setPhone("");
								setCompanyName("");
								setGuestTotal("");
								setCareer("");
								setSelectedValue(null);
								setIsModalOpen(true);
								setIsLoading(false);
							}
						});
					}
				});
		} else {
			message.error(ConstantLanguage.messageError);
		}
	};

	return (
		<div className="consult">
			<img src={BGConsult} alt="" className="consult__image" />
			<div className="consult__container">
				{/* <div className="consult__container__card">  */}
				<p className="consult__title">{ConstantLanguage.consult}</p>
				<div className="consult__form">
					<div className="info_box">
						<div className="contact_box">
							<p className="consult__sub">{ConstantLanguage.consult__sub}</p>
							<Input
								placeholder={ConstantLanguage.consult__name + "*"}
								className="consult__form--input"
								onChange={(e) => setName(e.target.value)}
								value={name}
							/>

							<Input
								placeholder={ConstantLanguage.consult__email + "*"}
								className="consult__form--input"
								onChange={(e) => setEmail(e.target.value)}
								value={email}
							/>
							<Input
								placeholder={ConstantLanguage.consult__phone + "*"}
								className="consult__form--input"
								onChange={(e) => setPhone(e.target.value)}
								value={phone}
							/>
							<Input
								placeholder={ConstantLanguage.consult__companyName}
								className="consult__form--input"
								onChange={(e) => setCompanyName(e.target.value)}
								value={companyName}
							/>
							<Input
								placeholder={ConstantLanguage.consult__career}
								className="consult__form--input"
								onChange={(e) => setCareer(e.target.value)}
								value={career}
							/>
						</div>
						{/* Thông tin SỰ KIỆN */}
						<div className="event_box">
							<p className="consult__sub">
								{ConstantLanguage.consult__sub_event}
							</p>

							<Select
								className="consult__form--select"
								placeholder={ConstantLanguage.consult__typeOfEvent + "*"}
								optionLabelProp="children"
								onChange={(e) => setSelectedValue(e)}
								value={selectedValue}
								options={[
									{
										disabled: true,
										value: ConstantLanguage.consult__typeOfEvent + "*",
										label: ConstantLanguage.consult__typeOfEvent + "*",
									},
									{
										value: ConstantLanguage.consult_corporate_event,
										label: ConstantLanguage.consult_corporate_event,
									},
									{
										value: ConstantLanguage.consult_personal_event,
										label: ConstantLanguage.consult_personal_event,
									},
								]}
							/>
							<Input
								type="Number"
								placeholder={ConstantLanguage.consult__guestTotal + "*"}
								className="consult__form--input"
								onChange={(e) => setGuestTotal(e.target.value)}
								value={guestTotal}
								id="positiveNumberInput"
							/>

							<DatePicker
								locale={isChangeLanguage == "vi" ? locale : null}
								format={"DD/MM/YYYY"}
								className="consult__form--input"
								placeholder={ConstantLanguage.consult__date + "*"}
								onChange={(date, dateString) => setDate(dateString)}
								value={date === "" ? "" : dayjs(date, "DD/MM/YYYY")}
								disabledDate={(current) => {
									return moment().add(-1, "days") >= current;
								}}
								style={{ fontSize: "25px" }}
							/>

							<TextArea
								rows={4}
								placeholder={ConstantLanguage.consult__content}
								className="consult__form--input"
								id="consult_textArea"
								autoSize={false}
								onChange={(e) => setContent(e.target.value)}
								value={content}
								style={{
									padding: "5px 17px 4px",
									maxHeight: "113px",
									minHeight: "113px",
								}}
							/>
						</div>
					</div>
					<div className="consult__form--button" onClick={() => handleSubmit()}>
						<span
							style={{ paddingRight: isChangeLanguage == "en" ? "20px" : "0" }}
						>
							{ConstantLanguage.consult__submitForm}
						</span>
						<div className="consult__form--button--wrapper">
							<svg
								width="16"
								height="12"
								viewBox="0 0 16 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M8 6.84766C8.31641 6.53125 8.31641 6.00391 8 5.65234L3.21875 0.871094C2.86719 0.554688 2.33984 0.554688 2.02344 0.871094L1.21484 1.67969C0.898438 2.03125 0.898438 2.55859 1.21484 2.875L4.625 6.28516L1.21484 9.66016C0.898438 9.97656 0.898438 10.5039 1.21484 10.8555L2.02344 11.6289C2.33984 11.9805 2.86719 11.9805 3.21875 11.6289L8 6.84766ZM14.75 5.65234L9.96875 0.871094C9.61719 0.554688 9.08984 0.554688 8.77344 0.871094L7.96484 1.67969C7.64844 1.99609 7.64844 2.55859 7.96484 2.875L11.375 6.25L7.96484 9.66016C7.64844 9.97656 7.64844 10.5039 7.96484 10.8555L8.77344 11.6289C9.08984 11.9805 9.65234 11.9805 9.96875 11.6289L14.75 6.84766C15.0664 6.53125 15.0664 6.00391 14.75 5.65234Z"
									fill="white"
								/>
							</svg>
						</div>
					</div>
				</div>
				{/* </div> */}
			</div>
			{isLoading ? (
				<div
					className="loading"
					style={{
						position: "fixed",
						top: "0",
						left: "0",
						width: "100vw",
						height: "100vh",
						zIndex: "101",
						background: "rgba(32, 32, 32, 0.7)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						color: "white",
						fontSize: "50px",
					}}
				>
					<LoadingOutlined />
				</div>
			) : (
				<div className=""></div>
			)}
			<NotifySucess
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				header={ConstantLanguage.MODAL_THANK_HEADER}
				content={ConstantLanguage.MODAL_THANK_CONTENT}
			/>
		</div>
	);
}

export default Consult;

import React from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Carousel from "react-bootstrap/Carousel";
import ImageCarousel1 from "../asset/Event/1.png";
import ImageCarousel2 from "../asset/Event/2.jpg";
import ImageCarousel3 from "../asset/Event/3.jpg";
import ImageCarousel4 from "../asset/Event/4.png";
import ImageCarousel5 from "../asset/Event/5.jpg";
import ImageCarousel6 from "../asset/Event/6.jpg";
import ImageCarousel7 from "../asset/Event/7.jpg";
import ImageCarousel8 from "../asset/Event/8.jpg";
// import ImageCarousel9 from "../asset/tri-an-khach-hang.png";

function Slider(props) {
	const {
		ConstantLanguage,
		isChangeLanguage,
		handleScrollToConsult,
		windowSize,
	} = props;
	Fancybox.bind('[data-fancybox="galleryEvent"]', {
    compact: false,
    contentClick: "iterateZoom",
    Images: {
      Panzoom: {
        maxScale: 2,
      },
    },
    Toolbar: {
      display: {
        left: [
          "infobar",
        ],
        middle : [],
        right: [
          "iterateZoom",
          "close",
        ],
      }
    }
  });  
	return (
		<div className="slider-section">
			<div>
				<p className="event__head-title">
					{ConstantLanguage.nowHappening__headSub}
				</p>
			</div>
			<Carousel interval={8000} pause="false">
				<Carousel.Item>
					<a data-fancybox="galleryEvent" href={ImageCarousel1}>
						<img src={ImageCarousel1} alt="" />
					</a>
				</Carousel.Item>
				<Carousel.Item>
					<a data-fancybox="galleryEvent" href={ImageCarousel2}>
						<img src={ImageCarousel2} alt="" />
					</a>
				</Carousel.Item>
				<Carousel.Item>
					<a data-fancybox="galleryEvent" href={ImageCarousel3}>
						<img src={ImageCarousel3} alt="" />
					</a>
				</Carousel.Item>
				<Carousel.Item>
					<a data-fancybox="galleryEvent" href={ImageCarousel4}>
						<img src={ImageCarousel4} alt="" />
					</a>
				</Carousel.Item>
				<Carousel.Item>
					<a data-fancybox="galleryEvent" href={ImageCarousel5}>
						<img src={ImageCarousel5} alt="" />
					</a>
				</Carousel.Item>
				<Carousel.Item>
					<a data-fancybox="galleryEvent" href={ImageCarousel6}>
						<img src={ImageCarousel6} alt="" />
					</a>
				</Carousel.Item>
				<Carousel.Item>
					<a data-fancybox="galleryEvent" href={ImageCarousel7}>
						<img src={ImageCarousel7} alt="" />
					</a>
				</Carousel.Item>
				<Carousel.Item>
          <img src={ImageCarousel8} alt="" />
        </Carousel.Item>
				{/* <Carousel.Item>
          <img src={ImageCarousel9} alt="" />
        </Carousel.Item> */}
			</Carousel>
			<div className="slider-section__content">
				<p>{ConstantLanguage.long_content}</p>
			</div>
		</div>
	);
}

export default Slider;

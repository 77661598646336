import React from "react";
import BGgoogleMap from "../asset/Top_Banner_1920x1080_2.jpg";

function Googlemap(props) {
  const { ConstantLanguage } = props;
  return (
    <div className="googleMap">
      <img src={BGgoogleMap} alt="" className="googleMap__image" />
      <div className="googleMap__container">
        <div className="googleMap__container-wrapper" >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.5139235861097!2d106.71849057575251!3d10.771894889376634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f5f99c27961%3A0x516fc881d67a6c88!2zVHJ1bmcgdMOibSBo4buZaSBuZ2jhu4sgVGhpc2t5aGFsbCBTYWxh!5e0!3m2!1svi!2s!4v1696581026619!5m2!1svi!2s"
            height="400"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Googlemap;

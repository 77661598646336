import React from "react";
import IconArown from "../asset/icon-arrown.svg"

function OurServices(props) {
	const { ConstantLanguage, handleScrollToConsult } = props;
	return (
		<div className="our-services">
			<div className="our-services__head">
				<p className="our-services__head-title">
					{ConstantLanguage.ourServices__headTitle}
				</p>
				<p className="our-services__head-sub">
					{ConstantLanguage.ourServices__headSub}
				</p>
			</div>
			<div className="our-services__body">
				<div className="our-services__item">
					<div className="our-services__item-left">
						{/* <img src={BallRoom} alt="" /> */}
					</div>
					<div className="our-services__item-right">
						<p className="our-services__item-title">
							{ConstantLanguage.ourServices__ballRoom}
						</p>
						<p className="our-services__item-sub">
							{ConstantLanguage.ourServices__ballRoomSub}
						</p>
						<p className="our-services__item-area">
							{ConstantLanguage.ourServices__ballRoomArea}
						</p>
						<div className="our-services__item-button" onClick={() => {handleScrollToConsult()}}>
							<span>{ConstantLanguage.ourServices__booking}</span>
							<div className="our-services__item-button--wrapper">
								<img src={IconArown} alt="" />
							</div>
						</div>
					</div>
				</div>
				<div className="our-services__item">
					<div className="our-services__item-left">
						{/* <img src={VipRoom} alt="" /> */}
					</div>
					<div className="our-services__item-right">
						<p className="our-services__item-title">
							{ConstantLanguage.ourServices__vipRoom}
						</p>
						<p className="our-services__item-sub">
							{ConstantLanguage.ourServices__vipRoomSub}
						</p>
						<p className="our-services__item-area">
							{ConstantLanguage.ourServices__vipRoomArea}
						</p>
						<div className="our-services__item-button" onClick={() => {handleScrollToConsult()}}>
							<span>{ConstantLanguage.ourServices__booking}</span>
							<div className="our-services__item-button--wrapper">
								<img src={IconArown} alt="" />
							</div>
						</div>
					</div>
				</div>
				<div className="our-services__item">
					<div className="our-services__item-left">
						{/* <img src={ConferenceRestaurant} alt="" /> */}
					</div>
					<div className="our-services__item-right">
						<p className="our-services__item-title">
							{ConstantLanguage.ourServices__conferenceRoom}
						</p>
						<p className="our-services__item-sub">
							{ConstantLanguage.ourServices__conferenceRoomSub}
						</p>
						<p className="our-services__item-area">
							{ConstantLanguage.ourServices__conferenceRoomArea}
						</p>
						<div className="our-services__item-button" onClick={() => {handleScrollToConsult()}}>
							<span>{ConstantLanguage.ourServices__booking}</span>
							<div className="our-services__item-button--wrapper">
								<img src={IconArown} alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OurServices;

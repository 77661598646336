import { Modal } from "antd";
import React from "react";
import doneImage from "../asset/check.png";

function NotifySucess({ isModalOpen, setIsModalOpen, header, content }) {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      open={isModalOpen}
      footer={[]}
      centered
      onCancel={handleCancel}
      style={{
        textAlign: "center",
      }}
      width={400}
    >
      <img src={doneImage} alt="done" style={{ width: 128 }} />
      <h2 style={{ fontSize: "30px", color: "#000", fontWeight: "bold" }}>
       {header}
      </h2>
      <p
        style={{
          fontSize: "16px",
          maxWidth: "320px",
          margin: "auto",
        }}
      >
        {content}
      </p>
    </Modal>
  );
}

export default NotifySucess;

import React, { useEffect, useRef, useState } from "react";
import ImageCarousel1 from "../asset/Dai sanh da chuc nang.jpg";
import ImageCarousel2 from "../asset/He thong sanh.jpg";
import ImageCarousel3 from "../asset/Trang thiet bi toi tan.jpg";
import ImageCarousel4 from "../asset/He thong moc treo tran.jpeg";
import ImageCarousel5 from "../asset/He thong bai giu xe.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function WeProvide(props) {
	const { ConstantLanguage, handleScrollToConsult, windowSize } = props;
	const [slidesToShow, setSlidesToShow] = useState(3);
	const [arrowShow, setArrowShow] = useState(true);

  const createMakeup = (content) => {
		return { __html: content };
	};

	const updateSlidesToShow = () => {
		if (window.innerWidth >= 800 && window.innerWidth < 1000) {
			setSlidesToShow(2);
			// setArrowShow(false)
		} else if (window.innerWidth < 800) {
			setSlidesToShow(1);
			// setArrowShow(false)
		} else {
			// setArrowShow(true)
			setSlidesToShow(3);
		}
	};
	const settings = {
		autoplay: true,
		Speed: 8000,
		infinite: true,
		speed: 1000,
		slidesToShow: slidesToShow,
		slidesToScroll: 1,
		arrows: arrowShow,
		dots: true,
	};
	useEffect(() => {
		updateSlidesToShow();
		window.addEventListener("resize", updateSlidesToShow);
		return () => {
			window.removeEventListener("resize", updateSlidesToShow);
		};
	}, []);

	return (
		<div className="our-WeProvide">
			<div className="our-WeProvide__head">
				<p
					className="our-WeProvide__head-title"
					dangerouslySetInnerHTML={createMakeup(
						ConstantLanguage.ourWeProvide__headTitle,
					)}
				/>
				<div className="slider-section">
					<Slider {...settings}>
						<div className="WeProvide_card">
							<div className="WeProvide_card__inside">
								<img src={ImageCarousel1} alt="" />
								<div className="WeProvide_card__inside-subContent">
									<ul className="content_list">
										<li className="content_list_item">
											{/* <Tooltip title={ConstantLanguage.weProvide_1_1}> */}
											{ConstantLanguage.weProvide_1_1}
											{/* </Tooltip> */} {ConstantLanguage.weProvide_1_2}
										</li>
										{/* <li className="content_list_item">
                     
                    </li> */}
									</ul>
								</div>
							</div>
						</div>
						<div className="WeProvide_card">
							<div className="WeProvide_card__inside">
								<img src={ImageCarousel2} alt="" />
								<div className="WeProvide_card__inside-subContent">
									<ul className="content_list">
										<li className="content_list_item">
											{" "}
											{ConstantLanguage.weProvide_2_1}{" "}
											{ConstantLanguage.weProvide_2_2}
										</li>
										{/* <li className="content_list_item">
                     
                    </li> */}
									</ul>
								</div>
							</div>
						</div>
						<div className="WeProvide_card">
							<div className="WeProvide_card__inside">
								<img src={ImageCarousel3} alt="" />
								<div className="WeProvide_card__inside-subContent">
									<ul className="content_list">
										<li className="content_list_item">
											{" "}
											{ConstantLanguage.weProvide_3_1}{" "}
											{ConstantLanguage.weProvide_3_2}
										</li>
										{/* <li className="content_list_item">
                     
                    </li> */}
									</ul>
								</div>
							</div>
						</div>
						<div className="WeProvide_card">
							<div className="WeProvide_card__inside">
								<img src={ImageCarousel4} alt="" />
								<div className="WeProvide_card__inside-subContent">
									<ul className="content_list">
										<li className="content_list_item">
											{" "}
											{ConstantLanguage.weProvide_4_1}
										</li>
										{/* <li className="content_list_item">
                      {" "}
                      {ConstantLanguage.weProvide_4_2}
                    </li> */}
									</ul>
								</div>
							</div>
						</div>
						<div className="WeProvide_card">
							<div className="WeProvide_card__inside">
								<img src={ImageCarousel5} alt="" />
								<div className="WeProvide_card__inside-subContent">
									<ul className="content_list">
										<li className="content_list_item">
											{" "}
											{ConstantLanguage.weProvide_5_1}{" "}
											{ConstantLanguage.weProvide_5_2}
										</li>
										{/* <li className="content_list_item">
                     {" "}
                      {ConstantLanguage.weProvide_5_2}
                    </li> */}
									</ul>
								</div>
							</div>
						</div>
					</Slider>
				</div>
			</div>
		</div>
	);
}

export default WeProvide;

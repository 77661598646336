export const DEFINE_LANGUAGES_VI = {
	header__about: "Giới thiệu",
	header__explore: "Khám phá",
	header__event: "Sự kiện",
	header__booking: "Đặt chỗ",
	header__contact: "Liên hệ",
	booking: "Đặt chỗ",
	// NOW HAPPENING
	nowHappening__headTitle: "NOW HAPPENING",
	nowHappening__headSub: "SỰ KIỆN",
	nowHappening__leftTitle: "Green Economy Forum <br /> & Exhibition 2022",
	nowHappening__leftSub:
		"Sáng kiến và giải pháp bền vững Châu Âu dành cho Việt Nam",
	nowHappening__leftDesc:
		'Từ ngày 28 đến 30 tháng 11<br/>Tại Trung Tâm Hội Nghị THISKYHALL thuộc Khu đô thị Sala, Thành phố Hồ Chí Minh<br/><br/>Hiệp hội Doanh nghiệp châu Âu tại Việt Nam (EuroCham Việt Nam) sẽ tổ chức ""Diễn đàn & Triển lãm Kinh tế xanh (GEFE) 2022“. Mục tiêu chính của sự kiện là nhằm hỗ trợ Việt Nam đạt được các cam kết tại Hội nghị các bên tham gia Công ước khung của Liên Hợp Quốc về biến đổi khí hậu lần thứ 26 (COP26) cũng như các mục tiêu phát triển kinh tế - xã hội được nêu rõ trong Chiến lược quốc gia về tăng trưởng xanh giai đoạn 2021-2030.',
	nowHappening__download: "Tải brochure",
	// EXPLORE
	ourExplore__headTitle: "KHÁM PHÁ",
	ourExplore__headSub: "",
	// OUR WeProvide
	ourWeProvide__headTitle: "NÂNG TẦM TRẢI NGHIỆM TRONG TỪNG KHOẢNH KHẮC VỚI KHÔNG GIAN SẢNH ĐA DẠNG </br>VÀ TRANG THIẾT BỊ HIỆN ĐẠI",
	ourWeProvide__headSub: "THISKYHALL CONVENTION",
	// OUR SERVICES
	ourServices__headTitle: "OUR SERVICES",
	ourServices__headSub: "CÁC PHÒNG HỘI NGHỊ",
	ourServices__ballRoom: "MAIN BALLROOM",
	ourServices__ballRoomSub:
		"Với hệ thống ghế nhà hát di động, thang máy vận chuyển ô tô vào tất cả các sảnh; hệ thống móc treo toàn bộ hệ trần với tải trọng cao; hệ thống âm thanh ánh sáng thông minh; hệ thống vách ngăn có thể chia sảnh linh hoạt, Main Ballroom Thiskyhall cung cấp không gian đa chức năng, phù hợp với đa dạng loại hình và ý tưởng sự kiện.",
	ourServices__ballRoomArea: "Diện tích: 3.220 m2",
	ourServices__vipRoom: "BOUTIQUE BALLROOM",
	ourServices__vipRoomSub:
		" Với 04 sảnh hội nghị được trang bị công nghệ trình chiếu hiện đại, kết hợp không gian sự kiện ngoài trời và sảnh đón khách riêng biệt, Boutique Ballroom là địa điểm phù hợp tổ chức các chương trình hội họp, hội thảo, sự kiện công ty.",
	ourServices__vipRoomArea: "Diện tích: 137m2 - 119m2 - 119m2 - 116m2",
	ourServices__conferenceRoom: "VIP LOUNGE",
	ourServices__conferenceRoomSub:
		"Với không gian nội thất tinh tế và trang thiết bị hiện đại, tính bảo mật cao, VIP Lounge là sự lựa chọn phù hợp cho các hội thảo, sự kiện họp mặt đối tác quan trọng.",
	ourServices__conferenceRoomArea: "Diện tích: 149m2 - 86m2 - 155m2 - 155m2",
	ourServices__booking: "ĐẶT CHỖ",
	aboutUs: "VỀ THISKYHALL SALA",
	aboutUs__sub:
		'THISKYHALL SALA đại diện trung tâm hội nghị - sự kiện thế hệ mới nâng tầm chuẩn mực trong lĩnh vực tổ chức sự kiện cho nhà tổ chức & khách tham dự. Không chỉ là điểm đến cho những sự kiện quy mô đa chức năng theo tiêu chuẩn quốc tế & hiện đại nhất Việt Nam, THISKYHALL SALA còn là nơi cộng hưởng nhịp nhàng với tổ hợp mua sắm ẩm thực giải trí toàn diện của hệ sinh thái THISO GROUP.',
	consult: "ĐẶT CHỖ",
	consult__sub: "THÔNG TIN LIÊN HỆ",
	consult__submitForm: "GỬI THÔNG TIN",
	consult__name: "Họ và tên",
	consult__email: "Email",
	consult__phone: "Số điện thoại",
	consult__companyName: "Tên công ty",
	consult__career: "Ngành nghề",
	consult__date: "Ngày đặt chỗ",
	consult__guestTotal: "Số lượng khách",
	consult__typeOfEvent: "Loại hình sự kiện",
	consult__content: "Thông tin khác",
	consult__sub_event: "THÔNG TIN SỰ KIỆN",
	consult_corporate_event: "Sự kiện công ty",
	consult_personal_event: "Sự kiện cá nhân",
	footer__head: "LIÊN HỆ",
	footer__sub:
		"Lầu 5, Số 10 Mai Chí Thọ, Khu đô thị Sala, Thủ Thiêm, Thủ Đức, TPHCM",
	footer__end: " 2022 Thiskyhall. All right reserved.",
	messageError: "Vui lòng nhập đủ thông tin",
	long_content: "THISKYHALL SALA cung cấp không gian tổ chức sự kiện sang trọng với trang thiết bị hiện đại dành cho mọi loại hình sự kiện như: hội nghị, triển lãm, yến tiệc...cùng  hệ thống dịch vụ chuyên nghiệp, theo quy chuẩn quốc tế & tận tâm đến từng chi tiết. ",
	weProvide_1_1: "Sảnh Skylar đa chức năng với sức chứa lên tới 3,600 khách. Hệ thống trần cao 11m và có thể linh hoạt hạ thấp xuống 0.5m, cùng hệ thống vách ngăn cách âm di động, có thể phân tách thành 4 sảnh tiệc  lớn nhỏ.",
	// weProvide_1_2: "Hệ thống vách ngăn cách âm di động, có thể phân tách thành 4 sảnh tiệc lớn nhỏ.",
	weProvide_2_1: "Phong cách kiến trúc đương đại và trang thiết bị hoàn thiện theo tiêu chuẩn quốc tế, 12 sảnh sự kiện chính cùng Foyer Grand Skylar và Sky Garden có thể đáp ứng đa dạng loại hình sự kiện.",
	weProvide_2_2: "",
	weProvide_3_1: "",
	weProvide_3_2: "Trang thiết bị hiện đại hàng đầu mang đến cho người tham dự tổng hòa nhiều cung bậc cảm xúc, thỏa mãn thị giác và thính giác.",
	weProvide_4_1: "Hệ thống móc treo trần với gần 252 điểm treo ở Sảnh Skylar và 44 điểm treo ở Foyer Grand Skylar (tải trọng 500kg/điểm treo)",
	// weProvide_4_2: "Hệ thống thang hàng tải trọng lớn, có thể chuyển ô tô vào tận đại sảnh.",
	weProvide_5_1: "Hệ thống thang hàng lớn, dễ dàng vận chuyển xe hơi với tải trọng lên đến 3.5 tấn cùng với 14 thang khách & dịch vụ phục vụ cho các sự kiện.",
	weProvide_5_2: "",
	MODAL_THANK_HEADER: "Trân trọng cảm ơn!",
	MODAL_THANK_CONTENT: "Cám ơn bạn vì đã liên hệ. Chúng tôi sẽ phản hồi lại trong thời gian sớm nhất.",
};

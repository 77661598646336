import React from "react";
import Aboutus from "../asset/Giới thiệu.jpg";

function AboutUs(props) {
	const { ConstantLanguage } = props;
	const createMakeup = (content) => {
		return { __html: content };
	};
	return (
		<div className="about-us">
			<div className="about-us__container">
				<div className="about-us__left">
					<span className="about-us__left-title">{ConstantLanguage.aboutUs}</span>
					<p
						className="about-us__left-sub"
						dangerouslySetInnerHTML={createMakeup(
							ConstantLanguage.aboutUs__sub,
						)}
					/>
				</div>
				<div className="about-us__right">
					<div className="about-us__right-image">
						<img src={Aboutus} alt="" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default AboutUs;

// src/components/ImageGallery.js
import React, { useEffect, useState } from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import ImageCarousel1 from "../asset/explore/123_Khám phá-1550x800.jpg"
import ImageCarousel2 from "../asset/explore/VECTOR-MAP-5TH-6TH-THISKYHALL-231106-04-02.jpg";
import ImageCarousel3 from "../asset/explore/VECTOR-MAP-5TH-6TH-THISKYHALL-231106-04-03.jpg";
import ImageCarousel4 from "../asset/explore/VECTOR-MAP-5TH-6TH-THISKYHALL-231106-04-04.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Explore = (props) => {
  const { ConstantLanguage, isChangeLanguage, handleScrollToConsult, windowSize } = props;
  const [arrowShow, setArrowShow] = useState(true);
  Fancybox.bind('[data-fancybox="gallery"]', {
    compact: false,
    contentClick: "iterateZoom",
    Images: {
      Panzoom: {
        maxScale: 2,
      },
    },
    Toolbar: {
      display: {
        left: [
          "infobar",
        ],
        middle : [],
        right: [
          "iterateZoom",
          "close",
        ],
      }
    }
  });   
  const updateSlidesToShow = () => {

    if (window.innerWidth >= 800 && window.innerWidth < 1000) {
      // setArrowShow(false);
    } else if (window.innerWidth < 800) {
      // setArrowShow(false);
    } else {
      // setArrowShow(true);
  
    }
  };
  const settings = {
    autoplay:true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: arrowShow,
    slickdot: true,
    dots:true
  };
  useEffect(() => {
    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);
    return () => {
      window.removeEventListener("resize", updateSlidesToShow);
    };
  }, []);

  return (
    <div className="our-Explore">
      <div className="our-Explore__head">
        <p className="our-Explore__head-title">
          {ConstantLanguage.ourExplore__headTitle}
        </p>
        <div className="slider-section">
          <Slider {...settings}>
            <div className="Explore_card" >
              <a data-fancybox="gallery" href={ImageCarousel1}>
                <div className="Explore_card__inside">
                  <img  src={ImageCarousel1} alt="" />
                </div>
              </a>
            </div>
            <div className="Explore_card">
              <a data-fancybox="gallery" href={ImageCarousel2}>
                <div className="Explore_card__inside">
                  <img   src={ImageCarousel2} alt="" />
                </div>
              </a>
            </div>
            <div className="Explore_card">
              <a data-fancybox="gallery" href={ImageCarousel3}>
                <div className="Explore_card__inside">
                  <img  src={ImageCarousel3} alt="" />
                </div>
              </a>
            </div>
            <div className="Explore_card" >
              <a data-fancybox="gallery" href={ImageCarousel4}>
                <div className="Explore_card__inside">
                  <img src={ImageCarousel4} alt="" />
                </div>
              </a>
            </div>
          
          </Slider>
          {/* <div className="explore_download">
          <a
						className="now-happening__button"
						href={filePDF}
						download
					>
						<span
							style={{ paddingRight: isChangeLanguage == "vi" ? "20px" : "0" }}
						>
							{ConstantLanguage.nowHappening__download}
						</span>
						<div className="now-happening__button--image-wrapper">
							<img src={IconDownload} alt="" />
						</div>
					</a>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Explore;

import React from "react";
import LogoThiso from "../asset/Thiskyhall-Logo.png";
import IconMap from "../asset/icon-map.svg";
import IconPhone from "../asset/icons8-phone-100.png";
import IconCopyright from "../asset/copyright.svg";
import { MailFilled } from "@ant-design/icons";

function Footer(props) {
	const { ConstantLanguage } = props;
	return (
		<div className="footer">
			<div className="footer__head">
				<div className="footer__head-left">
					<span className="footer__head__title">
						{ConstantLanguage.footer__head}
					</span>
					<div className="footer__head__sub">
						<img src={IconMap} alt="" />
						<span>{ConstantLanguage.footer__sub}</span>
					</div>
					<div className="footer__head__sub" style={{ marginTop: 10 }}>
						<img src={IconPhone} alt="" />
						<span>(+84)86 717 7839</span>
					</div>
					<div className="footer__head__sub" style={{ marginTop: 10 }}>
						<MailFilled style={{ marginRight: 20, color: "#C99C28" }} />
						<span>thiskyhallsala@thiso.vn</span>
					</div>
				</div>
				<div className="footer__head-right">
					<img src={LogoThiso} alt="" />
				</div>
			</div>
			<hr />
			<div className="footer__end">
				<img src={IconCopyright} alt="" />
				<span style={{ color: "#ffe1a1s" }}>
					{ConstantLanguage.footer__end}
				</span>
			</div>
		</div>
	);
}

export default Footer;

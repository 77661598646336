export const DEFINE_LANGUAGES_EN = {
	// NOW HAPPENING
	header__about: "About",
	header__explore: "Explore",
	header__event: "Events",
	header__booking: "Booking",
	header__contact: "Contact",
	booking: "BOOKING",
	// NOW HAPPENING
	nowHappening__headTitle: "NOW HAPPENING",
	nowHappening__headSub: "EVENT",
	nowHappening__leftTitle: "Green Economy Forum <br /> & Exhibition 2022",
	nowHappening__leftSub:
		"European Innovations and Sustainable Solutions for Vietnam",
	nowHappening__leftDesc:
		"November 28 - 30, 2022<br/>THISKYHALL Convention, 10 Mai Chi Tho, Thu Thiem Ward, Thu Duc City, HCMC, VietNam<br/><br/>EuroCham Vietnam will host the Green Economy Forum & Exhibition (GEFE) 2022 to support Vietnam in achieving its COP26 commitments and its National Green Growth Strategy's socio-economic development goals.",
	nowHappening__download: "Download Brochure",
	// EXPLORE
	ourExplore__headTitle: "EXPLORE",
	ourExplore__headSub: "",
	// OUR WeProvide
	ourWeProvide__headTitle: "ELEVATE THE EXPERIENCE IN EVERY MOMENT WITH DIVERSE EVENT SPACES </br>AND STATE-OF-THE-ART EQUIPMENT",
	ourWeProvide__headSub: "THISKYHALL CONVENTION",

	// OUR SERVICES
	ourServices__headTitle: "OUR SERVICES",
	ourServices__headSub: "THISKYHALL CONVENTION",
	ourServices__ballRoom: "MAIN BALLROOM",
	ourServices__ballRoomSub:
		"With portable theater seat system, the elevator cars into all hall; the suspension of the whole ceiling with high loads; smart lighting and sound system; partition walls system that can split flexible hall. Main Ballroom Thiskyhall provides a multi-functional space, suitable for a variety of events and ideas.",
	ourServices__ballRoomArea: "Area: 3.220 m2",
	ourServices__vipRoom: "BOUTIQUE BALLROOM",
	ourServices__vipRoomSub:
		"With 04 conference halls equipped with modern projection technology, combining outdoor event space and separate reception hall, Boutique Ballroom is a suitable venue for conferencing, seminars and company events.",
	ourServices__vipRoomArea: "Area: 137m2 - 119m2 - 119m2 - 116m2",
	ourServices__conferenceRoom: "VIP LOUNGE",
	ourServices__conferenceRoomSub:
		"With sophisticated interior space and modern equipment, high confidential information, VIP Lounge is the best choice for seminars, important partner meetings.",
	ourServices__conferenceRoomArea: "Area: 149m2 - 86m2 - 155m2 - 155m2",
	ourServices__booking: "BOOKING",
	aboutUs: "ABOUT THISKYHALL SALA CONVENTION",
	aboutUs__sub:
		'THISKYHALL SALA is the representative of a new generation conference and event center that elevates standards in event organization for both organizers and attendees. More than just a destination for large-scale international standard events in Vietnam, THISKYHALL SALA harmoniously intergrates with the comprehensive shopping, dining and entertainment ecosystem of the THISO group.',
	consult: "BOOKING",
	consult__sub: "CONTACT INFORMATION",
	consult__submitForm: "SEND US",
	consult__name: "Name",
	consult__email: "Email",
	consult__phone: "Phone number",
	consult__companyName: "Company name",
	consult__career: "Career",
	consult__date: "Date of event",
	consult__guestTotal: "The number of guests",
	consult__typeOfEvent: "Type of event",
	consult_corporate_event: "Corporate event",
	consult_personal_event: "Personal event",
	consult__content: "Other information",
	consult__sub_event: "EVENT INFORMATION",
	footer__head: "CONTACT",
	footer__sub:
		"Level 5, 10 Mai Chi Tho, Sala Urban, Thu Thiem, Thu Duc City, HCMC",
	footer__end: " 2022 Thiskyhall. All right reserved.",
	messageError: "Please enter enough information",
	long_content: "THISKYHALL SALA provides luxurious event spaces with modern amenities for various types of events such as conferences, exhibitions, banquets, and more. Alongside a professional service system adhering to international standards, we are dedicated to every detail with heartfelt commitment.",
	weProvide_1_1: "Skylar is multipurpose hall, with a capacity of up to 3,600 guests. The ceiling system height of 11 meters that can be flexibly lowered to 0.5 meters. Additionally, Skylar can be divided into up to four separate halls using soundproof partitions, catering to various event scales.",
	weProvide_1_2: "",
	weProvide_2_1: "Contemporary architectural design and fully equipped to international standards, the 12 main event halls along with the Foyer Grand Skylar and Sky Garden can accommodate a diverse range of events.",
	weProvide_2_2: "",
	weProvide_3_1: "",
	weProvide_3_2: "State-of-the-art equipment brings a diverse range of emotions and visual and auditory satisfies attendees.",
	weProvide_4_1: "A ceiling hanging system with nearly 252 suspension points in Skylar and 44 suspension points in Foyer Grand Skylar (with a load capacity of 500 kg per suspension point).",
	weProvide_5_1: "The large-scale elevator system easily transports vehicles with a load capacity of up to 3.5 tons, along with 14 passenger elevators and services catering to events.",
	weProvide_5_2: "",
	MODAL_THANK_HEADER: "Sincerely thanks!",
	MODAL_THANK_CONTENT: "Thanks for contacting us. We will get back to you as soon as we can",
};

import './App.css';
import LandingPage from './Page/LandingPage';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (

    <LandingPage/>

  );
}

export default App;

import React, { useState, useRef, useEffect } from "react";
// import "../Style/NowHappening.scss";
import "../Style/OurServices.scss";
import "../Style/AboutUs.scss";
import "../Style/Consult.scss";
import "../Style/Footer.scss";
import "../Style/Header.scss";
import "../Style/Slider.scss";
import "../Style/WeProvide.scss";
import "../Style/Explore.scss";
import "../Style/Googlemap.scss";
import OurServices from "./OurServices";
import { DEFINE_LANGUAGES_VI } from "./constant_vi";
import { DEFINE_LANGUAGES_EN } from "./constant_en";
import AboutUs from "./AboutUs";
import Consult from "./Consult";
import Footer from "./Footer";
import Header from "./Header";
import Slider from "./Slider";
import WeProvide from "./WeProvide";
import Explore from "./Explore";
import Googlemap from "./Googlemap";
import NotifySucess from "./notifySucess";
// import NowHappening from "./NowHappening";

function LandingPage() {
  const [isChangeLanguage, setIsChangeLanguage] = useState("vi");
  const refNowHappening = useRef(null);
  const refExplore = useRef(null);
  const refSlider = useRef(null);
  const refAboutus = useRef(null);
  const refConsult = useRef(null);
  const refFooter = useRef(null);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const handleScrollToNowHappening = () => {
    refNowHappening.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollToExplore = () => {
    refExplore.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollToSlider = () => {
    refSlider.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollToAboutus = () => {
    refAboutus.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollToConsult = () => {
    refConsult.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollToFooter = () => {
    refFooter.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Header
        ConstantLanguage={
          isChangeLanguage === "vi" ? DEFINE_LANGUAGES_VI : DEFINE_LANGUAGES_EN
        }
        handleScrollToNowHappening={handleScrollToNowHappening}
        handleScrollToExplore={handleScrollToExplore}
        handleScrollToSlider={handleScrollToSlider}
        handleScrollToAboutus={handleScrollToAboutus}
        handleScrollToConsult={handleScrollToConsult}
        handleScrollToFooter={handleScrollToFooter}
        setIsChangeLanguage={setIsChangeLanguage}
        isChangeLanguage={isChangeLanguage}
        windowSize={windowSize}
      />
      <div className="" ref={refAboutus}>
        <AboutUs
          ConstantLanguage={
            isChangeLanguage === "vi"
              ? DEFINE_LANGUAGES_VI
              : DEFINE_LANGUAGES_EN
          }
        />
      </div>
      {/* <div className="" ref={refNowHappening}>
				<NowHappening
					ConstantLanguage={
						isChangeLanguage === "vi"
							? DEFINE_LANGUAGES_VI
							: DEFINE_LANGUAGES_EN
					}
					isChangeLanguage={isChangeLanguage}
					windowSize={windowSize}
					handleScrollToNowHappening={handleScrollToNowHappening}
				/>
			</div> */}

      {/* <div className="" ref={refExplore}>
				<OurServices
					ConstantLanguage={
						isChangeLanguage === "vi"
							? DEFINE_LANGUAGES_VI
							: DEFINE_LANGUAGES_EN
					}
					handleScrollToConsult={handleScrollToConsult}
				/>
			</div> */}
      <div className="">
        <WeProvide
          ConstantLanguage={
            isChangeLanguage === "vi"
              ? DEFINE_LANGUAGES_VI
              : DEFINE_LANGUAGES_EN
          }
        />
      </div>
      <div className="" ref={refExplore}>
        <Explore
          ConstantLanguage={
            isChangeLanguage === "vi"
              ? DEFINE_LANGUAGES_VI
              : DEFINE_LANGUAGES_EN
          }
          isChangeLanguage={isChangeLanguage}
        />
      </div>
      <div className="" ref={refSlider} style={{background: "#29261e", color: "white"}}>
        <Slider
          ConstantLanguage={
            isChangeLanguage === "vi"
              ? DEFINE_LANGUAGES_VI
              : DEFINE_LANGUAGES_EN
          }
          isChangeLanguage={isChangeLanguage}
        />
      </div>

      <div className="" ref={refConsult}>
        <Consult
          ConstantLanguage={
            isChangeLanguage === "vi"
              ? DEFINE_LANGUAGES_VI
              : DEFINE_LANGUAGES_EN
          }
          isChangeLanguage={isChangeLanguage}
        />
      </div>
      <div className="">
        <Googlemap
          ConstantLanguage={
            isChangeLanguage === "vi"
              ? DEFINE_LANGUAGES_VI
              : DEFINE_LANGUAGES_EN
          }
        />
      </div>

      <div className="" ref={refFooter}>
        <Footer
          ConstantLanguage={
            isChangeLanguage === "vi"
              ? DEFINE_LANGUAGES_VI
              : DEFINE_LANGUAGES_EN
          }
        />
      </div>
    </div>
  );
}

export default LandingPage;
